import React, { FC } from 'react'
import { Flex } from 'mui-blox'
import { useScript } from '~brix/connectors/context/useScript'
import { useQueryParams } from '../routing'
import { sciensio } from '../../config'
import { buildQuery } from '~core/util/buildQuery'
import { messengerVariants } from '~constants'
import { config } from '~config'

export const EmbeddedMessengerConversation: FC = () => {
  const cloudApiFeatureFlag = true // flags.cloud_function_messenger_api
  const { event } = useQueryParams()

  const baseUrl = cloudApiFeatureFlag
    ? `${config.firebase.exconPlaceMessengerApi}/api/v2/messenger/event/${event}`
    : `${sciensio.apiUrl}/api/messenger/event/${event}`

  const scriptUrl = buildQuery(`${baseUrl}`, {
    variant: messengerVariants.NO_FRAME,
    height: '100%',
    width: '100%',
  })

  useScript(scriptUrl)

  return (
    <Flex flexColumn>
      <div id='42chat-messenger' style={{ height: '100%', width: '100%' }} />
    </Flex>
  )
}
